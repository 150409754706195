import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { ChatCard } from './chat-card';

interface ChatCardDisplayProps {
  messages: {
    user_id: string;
    timestamp: number;
    name: string;
    message: string;
    message_id: string;
  }[];
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse;

  box-sizing: border-box;

  height: 100%;
  width: 100%;

  padding: 4rem;
  padding-bottom: 6rem;
`;

export const ChatCardDisplay = (props: ChatCardDisplayProps) => {
  const { messages } = props;

  const [timeStamp, setTimeStamp] = useState<number>(Date.now());

  const [lastColor, setLastColor] = useState({
    userId: '',
    messageId: '',
    colorId: 0,
  });

  useEffect(() => {
    if (messages.length > 0) {
      setLastColor((prev) => {
        if (prev.userId === messages[0].user_id) {
          return { ...prev, messageId: messages[0].message_id };
        }
        return {
          messageId: messages[0].message_id,
          userId: messages[0].user_id,
          colorId: prev.colorId === 0 ? 1 : 0,
        };
      });
    }
  }, [messages]);

  const output = useMemo(() => {
    const colourOptions = ['#FF8615', '#87FF5D'];
    let currentColor = lastColor.colorId;

    return messages.map((message, idx) => {
      if (idx > 0) {
        currentColor =
          message.user_id === messages[idx - 1].user_id
            ? currentColor
            : +!currentColor;
      }
      return (
        <ChatCard
          key={`chatCard-${message.message_id}-${timeStamp}`}
          chatData={message}
          colour={colourOptions[currentColor]}
        />
      );
    });
  }, [lastColor.colorId, messages, timeStamp]);

  useEffect(() => {
    // const currentTime = Date.now();
    const updatedTimeStamp = setInterval(
      () => setTimeStamp(() => Date.now()),
      1000 * 60
    );
    return () => {
      clearInterval(updatedTimeStamp);
    };
  }, []);

  return <Container>{output}</Container>;
};
