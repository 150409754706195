import styled from '@emotion/styled';
import { useChatContext } from '@shared/ui/contexts';

import { ChatCardDisplay } from '../components/chat-display';

const Container = styled.div`
  display: flex;
  position: relative;

  width: 100%;
  height: 100%;
`;

export const ChatLayout = () => {
  const { messages } = useChatContext();

  const reducedMsgs = messages.map(({ message_id, payload }) => {
    return {
      ...payload,
      message_id,
    };
  });

  return (
    <Container>
      <ChatCardDisplay messages={reducedMsgs} />
    </Container>
  );
};
