import { AspectRatioKeeper } from '@shared/ui/components/generic';
import { WebSocketProvider, ChatContextProvider } from '@shared/ui/contexts';

import { ChatLayout } from './layouts/app-layout';

const url = new URLSearchParams(window.location.search);
const token = url.get('token');

export const App = () => {
    if (!token) return <div>Invalid token</div>;

    return (
        <WebSocketProvider token={token} useTokenHost>
            <ChatContextProvider messageLimit={50}>
                <AspectRatioKeeper isPortrait={true}>
                    <ChatLayout />
                </AspectRatioKeeper>
            </ChatContextProvider>
        </WebSocketProvider>
    );
};
