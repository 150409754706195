import styled from '@emotion/styled';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2.7rem;
    width: 100%;
    font-size: 2.7rem;

    &.old-message {
        opacity: 45%;
        transition: 500ms;
    }
`;

const MessageTime = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: start;
    color: #747474;
    width: 15%;

    .time-stamp {
        line-height: 4.2rem;
        font-size: 2rem;
    }
`;

const Message = styled.div<{ colour: string }>`
    display: flex;
    justify-content: start;
    width: 80%;

    .name {
        margin-right: 1.5rem;
        text-transform: uppercase;
        color: ${({ colour }) => colour};
    }

    .chat {
        color: #ffffff;
        line-height: 8rem;
        font-size: 6rem;
    }
`;

export interface ChatProps {
    chatData: {
        name: string;
        message: string;
        timestamp: number;
    };
    colour: string;
}

export const ChatCard = (props: ChatProps) => {
    const { chatData, colour } = props;
    const { name, message, timestamp } = chatData;

    const elapsedTime = Math.floor(
        (new Date().getTime() - new Date(timestamp).getTime()) / 1000 / 60
    );

    const timeText = elapsedTime > 0 ? `${elapsedTime} min ago` : 'Just now';

    return (
        <Container className={elapsedTime > 4 ? 'old-message' : undefined}>
            <Message colour={colour}>
                <p className="chat">
                    <span className="name">{name}</span>
                    {message}
                </p>
            </Message>
            <MessageTime>
                <span className="time-stamp">{timeText}</span>
            </MessageTime>
        </Container>
    );
};
